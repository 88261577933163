.order-refunds-accordion div {
  color: rgba(0, 0, 0, 0.6) !important;
}

.order-refunds-accordion .title {
  padding: 0 !important;
}

.order-refunds-accordion > .content {
  padding-top: 0 !important;
  padding-left: 1.5em !important;
}

.order-refunds-accordion > .content i.icon {
  vertical-align: text-top !important;
  padding-top: 8px !important;
}

.order-refunds-accordion > .content i.close.icon {
  cursor: pointer;
}
.order-refunds-accordion > .content i.close.icon:hover {
  color: red;
}

.order-refunds-accordion > .content i.mini.icon,
.order-refunds-accordion > .content i.small.icon {
  padding-left: 2px !important;
  padding-right: 0 !important;
  padding-top: 5px !important;
  min-width: 12px !important;
  width: 9px !important;
}

.registrant-edit-card-order-table-refunds .order-refunds-accordion > .content i.icon {
  padding-top: 7px !important;
}
.registrant-edit-card-order-table-refunds .order-refunds-accordion > .content i.close.icon {
  padding-top: 4px !important;
}
